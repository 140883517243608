import React from 'react';
import CareersTile from './CareersTile';

const CareersMain = () => {
  return (
    <div>
      <div className="max-w-4xl mx-auto px-4 pt-36">
            <h2 className="text-5xl font-extrabold mb-4 text-gray-800"><span className="text-[#D00070]">SlopeX</span>celerity</h2>
            <p className="text-3xl font-bold mb-6 text-gray-700"><span className="block md:inline">우리의 도전은</span> 천안에서 시작합니다.</p>
            <p className="text-lg font-normal text-gray-600 leading-relaxed">
                SlopeXcelerity는 우리의 삶에 필요한 기초공학교육과 관련된 다양한 프로젝트를 시도하는 기업입니다.<br/><br/>
                모든 교육은 비전공자, 청소년도 접근할 수 있도록 설계되었습니다.
                우리는 컴퓨터 과학을 시작으로, 수학, 영어, 그리고 다양한 공학 분야의 기초를 탄탄히 다지는 혁신적인 교육 방식을 통해 미래의 인재를 양성합니다.<br/><br/>
            </p>
            <p className="text-lg font-normal text-gray-600 leading-relaxed">
                00MATH, 01CS 등의 기초공학교육 브랜드를 개발합니다.
                그리고 학습자에게 맞춤형 교육을 제공하기 위해 SlopeX AI와 적응형 학습을 탑재한 LMS를 제공합니다.<br/><br/>
            </p>
            <p className="text-lg font-normal text-gray-600 leading-relaxed">
                SlopeXcelerity와 함께 기초공학교육과 관련된 다양한 프로젝트를 함께하며 성장할 팀원을 찾고 있습니다.
            </p>
            <br/><br/>
        </div>
        <div className="max-w-full mx-auto ">
            <CareersTile />
        </div>
    </div>
  );
};

export default CareersMain;
